import { SearhcResultSet } from "../PurchaseSoftware";
import CheckoutConstants from '../constants/checkout-constants';

declare type ProductSearchCache = {
    response: SearhcResultSet,
    lastAccessedAt: Date,
};

class ProdutSearchCacheServices {
    private static setTimeoutProcessId: NodeJS.Timeout;
    private static cache: Map<string, ProductSearchCache>;

    private static startClearCacheService() {
        ProdutSearchCacheServices.setTimeoutProcessId = setTimeout(ProdutSearchCacheServices.clearCache,CheckoutConstants.CLEAR_CACHE_SET_TIMEOUT);
    }

    private static exceedsThanRequiredTime(currentTimeStamp, lastAccessedTimeStamp) {
        return (Math
            .floor(currentTimeStamp - lastAccessedTimeStamp) / 1000)
            > CheckoutConstants.CACHE_TIME_LIMIT;
    }

    /**
     * @description Clears cache keys which exists more that CheckoutConstants.CACHE_TIME_LIMIT
     */
    private static clearCache() {
        const currentTimeStamp = new Date();
        for (let [key, value] of ProdutSearchCacheServices.cache.entries()) {
            if (ProdutSearchCacheServices.exceedsThanRequiredTime(currentTimeStamp, value.lastAccessedAt)) {
                ProdutSearchCacheServices.cache.delete(key);
            }
        }
        ProdutSearchCacheServices.startClearCacheService();
    }

    private static isClearCacheStarted() {
        return ProdutSearchCacheServices.setTimeoutProcessId;
    }

    private static clearCacheService() {
        clearTimeout(ProdutSearchCacheServices.setTimeoutProcessId);
    }

    static addToCache(key: string, response:SearhcResultSet) {
        if(!ProdutSearchCacheServices.cache) {
            ProdutSearchCacheServices.cache = new Map();
        }
        ProdutSearchCacheServices.cache.set(key.toLowerCase().trim(),{response:{...response}, lastAccessedAt: new Date()});
        //Is this the first entry in cache?
        if(!ProdutSearchCacheServices.isClearCacheStarted()) {
            ProdutSearchCacheServices.startClearCacheService();
        }
        return true;
    }

    static get(searchKey: string): SearhcResultSet | undefined {
        const key = searchKey.toLowerCase().trim();
        if(ProdutSearchCacheServices.cache && ProdutSearchCacheServices.cache.has(key)) {
            const productSearchCache:any = {...ProdutSearchCacheServices.cache.get(key)};
            if(productSearchCache) {
                ProdutSearchCacheServices.addToCache(key,productSearchCache.response);
                return productSearchCache.response;
            }
        }
        return undefined;
    }

    static reset() {
        if( ProdutSearchCacheServices.cache) {
            ProdutSearchCacheServices.cache.clear();
            if(ProdutSearchCacheServices.isClearCacheStarted()) {
                ProdutSearchCacheServices.clearCacheService();
            }
        }
    }
}

export default ProdutSearchCacheServices;