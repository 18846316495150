import API from './api';
import { CategoryListingAPIRes } from '../PurchaseSoftware/';
import { CheckoutServiceURL } from '../constants/checkout-constants';

class CategoryListing {

    static async getCategoryListing(): Promise<CategoryListingAPIRes> {
        let response = await API.get(`${CheckoutServiceURL.PRODUCT_LISTING_URL}?fields=categories`);
        return response.data;
    }

}

export default CategoryListing;