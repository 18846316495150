import * as React from "react";
//TO DO: Need to update phone number
export const  ContactUsMessage = (props)=> {
  return (
    <div>
      {props.children} please write to &nbsp;
      {/* Honeywell support at <a href="mailto:Aerotechsupport@honeywell.com">Aerotechsupport@honeywell.com </a>
      (or) call us at <a href="tel:855-808-6500">855-808-6500</a>. */}
      Honeywell support at <a href="mailto:dsa@honeywell.com">dsa@honeywell.com </a>
      (or) call us at <a href="tel:602-436-6738">602-436-6738</a>.
    </div>
  );
};
