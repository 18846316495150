import Reducer from './reducer';
import { AsdsReduxAction } from '../../../../types';
import { createReducer } from '../utils/redux-util';
import ActionConstants from '../action-constants';
import { CategoryListingAPIRes } from '../../PurchaseSoftware/';

class ProductListingReducer extends Reducer {

    static getProductListing(_state, action: AsdsReduxAction) {
        return {
            payload: {
                productListing: {
                    ..._state.payload.productListing,
                    isFetching: true, 
                    isSucces:false,
                }, 
                isFetching: action.payload.isFetching 
            },
        };
    }

    static setProductListing(_state, action: AsdsReduxAction) {
        const categoryListingResponse: CategoryListingAPIRes = action.payload.response;
        return {
            payload: {
                productListing: {
                     data: categoryListingResponse, isFetching: action.payload.isFetching, isSuccess: true 
                    }, 
                    selectedCategory: action.payload.selectedCategory,
                isFetching: action.payload.isFetching,
            }
        };
    }

    static dispatchProductListingError(_state, action: AsdsReduxAction) {
        const error = action.payload;
        return {
            payload: {
                productListing: {
                    isFetching: false,
                    error
                }
            }
        };
    }
}

const productListingHandlers = {
    [ActionConstants.GET_PRODUCT_LISTING]: ProductListingReducer.getProductListing,
    [ActionConstants.SET_PRODUCT_LISTING]: ProductListingReducer.setProductListing,
    [ActionConstants.SET_PRODUCT_LISTING_ERROR]: ProductListingReducer.dispatchProductListingError,
}

const initialState = {
    payload : {
        productListing: { 
            isFetching: true, 
            isSuccess: true,
            error: {},
        }, 
        selectedCategory: '',
    }
};

export const products = createReducer(initialState, productListingHandlers);

