import Action from './Action'
import ActionConstants from '../action-constants';
import CategoryListingAPI from '../../API/category-listing-api';
import { SelectCategoryValue } from '../../PurchaseSoftware';
import { AsdsReduxAction } from '../../../../../types';

class CategoryListingActions extends Action {

    static getCategoryListing() {
        return (dispatch) => {
            CategoryListingAPI.getCategoryListing().then(response => {
                dispatch({
                    type: ActionConstants.SET_CATEGORY_LISTING,
                    payload : {
                        response,
                        selectedCategory: {
                            categoryId: 'default', 
                            system: '',
                        },
                    }
                });
            });
            dispatch({
                type: ActionConstants.GET_CATEGORY_LISTING,
                payload : {}
            });
        }
    }

    static setCategorySelected(selectedValue: SelectCategoryValue): AsdsReduxAction {
        return {
            type: ActionConstants.SET_SELECTED_CATEGORY,
            payload: {
                selectedCategory: selectedValue,
            }
        }
    }
}

export default CategoryListingActions;