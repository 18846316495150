/*******************************************************************************
 * @author: H175591
 * @description: This property deals with all the constants used in checkout module.
 *
 *
 * By            : E807958
 * On            : Nov 21, 2019
 * Description   : ASDS-1801, ASDS-1802, ASDS-1803, ASDS-1804
 *
 * By           : H224423
 * On           : Nov 28, 2019
 * Description  : ASDS-1474 Accept product terms changes.
 *
 * By            : SandeepRaja
 * On            : NOV 28, 2019
 * Description   : ASDS-1809: Order Summary - Wireless Products
 *
 * By            : Madhu Gali - H175591
 * On            : Dec 03 2019
 * Description   : ASDS-1964
 *
 * By            : Deepthi (E844276)
 * On            : Dec 5, 2019
 * Description   : ASDS-1961: href link to T&C document
 *
 * By            : Madhu Gali (H175591)
 * On            : Dec 12, 2019
 * Description   : ASDS-584
 *
 * By            : Madhu Gali - H175591
 * On            : Jan 10, 2019
 * Description   : ASDS-2152
 *
 * By            : Deepthi Bachu(E844276)
 * On            : Jan 28, 2020
 * Description   : ASDS-2048 - Admin Functionality
 *
 * By            : Madhu Gali - H175591
 * On            : Feb 05, 2020
 * Description   : ASDS-2271
 *
 * By            : H224423
 * On            : Feb 05, 2020
 * Description   : Updated URLS for the checkout services.
 *
 * By            	: Snigdha
 * On            	: Feb 10, 2019
 * Description    : ASDS-2268
 *
 * By            : Madhu Gali - H175591
 * On            : Feb 19, 2020
 * Description   : ASDS-2355
 *
 * By            : Deepthi Bachu (E844276)
 * On            : Mar 19, 2020
 * Description   : ASDS-2443 - Added My Licenses url for Navigation from checkout
 *
 * By            : H224423
 * On            : Mar 23, 2019
 * Description   : ASDS-1052 Added Logout URL
 *
 * By            : H224423
 * On            : Apr 03 2020
 * Description   : ASDS-2190 - API error handling changes.
 *
 *
 * By            : Srinivas Grandhi (E528634)
 * On            : Apr 06,2020
 * Description   : updated code as per ASDS-2432.
 *
 * By            : H224423
 * On            : 16 Apr, 2020
 * Description   : Previous selected address not be prefilled for Admin.
 *
 * By             : H422337
 * On             : Sep 07,2020
 * Description    : ASDS-2570 UI enhancements as part of phase-2
 *
 * By            : H422336
 * On            : Sep 07 2020
 * Description   : ASDS-2748 - UI Enhancements as part of Phase II
 *
 * By             : H422337
 * On             : Sep 21,2020
 * Description    : ASDS-3030 APU enhancements as part of phase-2
 *
 * By             : H422337
 * On             : Sep 24,2020
 * Description    : ASDS-3026 APU enhancements as part of phase-2
 *
 * By            : H422336
 * On            : Sep 23, 2020
 * Description   : ASDS - 3020 - UI Enhancements for Phase - II
 *
 *  By            : H422337
 * On             : Sep 25,2020
 * Description    : ASDS-3028 APU enhancements as part of phase-2
 *
 * By             : H422337
 * On             : Sep 30,2020
 * Description    : ASDS-3230 APU enhancements as part of phase-2
 *
 * By            : H422336
 * On            : Sep 29, 2020
 * Description   : ASDS - 3233 UI Enhancements for Phase - II
 *
 * By            : H422336
 * On            : Oct 01, 2020
 * Description   : ASDS - 3258 UI Enhancements for Phase - II
 *
 * By             : H422337
 * On             : Oct 05,2020
 * Description    : ASDS-3503 UI enhancements  phase-2 bugfixes
 *
 * By            : H422336
 * On            : Oct 09, 2020
 * Description   : ASDS - 3259 UI Enhancements for Phase - II
 *
 * By            : H422337
 * On            : Oct 08, 2020
 * Description   : ASDS - 3228 UI Enhancements for Phase - II
 *
 * By            : H422337
 * On            : Oct 08, 2020
 * Description   : ASDS - 3438 UI Enhancements for phase-2 bugfixes
 *
 * By            : H422336
 * On            : Oct 11, 2020
 * Description   : ASDS - 3255 UI Enhancements for Phase - II
 *
 * By            : H422335
 * On            : Oct 09, 2020
 * Description   : ASDS-3035 Migration of backend URLs to Forge endpoints
 *
 * By            : H422337
 * On            : Oct 09, 2020
 * Description   : ASDS - 3261 UI Enhancements for phase-2 bugfixes
 *
 * By            : H422336
 * On            : Oct, 16 2020
 * Description   : ASDS-3268 UI Enhancements for Phase-II
 *
 * By            : H422335
 * On            : Oct 27, 2020
 * Description   : ASDS-3269 Migration of backend URLs to Forge endpoints
 *
 * By            : H422336
 * On            : Oct, 29 2020
 * Description   : ASDS-3793 UI Enhancements for Phase-II
 *
 * By            : H422337
 * On            : Nov 04, 2020
 * Description   : ASDS-3265 - UI Enhancements for Phase - II -migrate to Forge API & Delete MSA product toast message
 *
 * By            : H422337
 * On            : Nov,06 2020
 * Description   : ASDS-3833- Error hadling scenario - place an order
 *
 * By            : H422335
 * On            : Nov,26 2020
 * Description   : Updated credit card processing failure message to generic one
******************************************************************************/
export const CheckoutConstants = {

  DEFAULT_CATEGORY: 'default',
  DEFAULT_CATEGORY_TEXT: 'View All Products',
  IDLE_TIME_FOR_SEARCH_REQUEST: 500,
  CACHE_TIME_LIMIT: 180, // seconds, last accessed time for a record.
  CLEAR_CACHE_SET_TIMEOUT: 60,  // seconds, a background job which clears cache.
  APP_ENV: 'APP_ENV',
  CC_ACTIVE_TAB: 0,
  PO_ACTIVE_TAB: 1,
  CC_PROCESS_INITIATED: 0,
  CC_PROCESS_FAILED: -1,
  CC_PROCESS_SUCCESS: 1,
  CONFIGURE_CUSTOMER_CATEGORY_TAB: 1,
  CONFIGURE_SERIAL_NUMBERS_TAB: 1,
  CONFIGURE_FEATURES_TAB: 2,
  CONFIRM_CONFIGURATION_TAB: 3,
  BULK_FILE_UPLOAD_TAB: 4,
  PREVIOUS_PATH: 'PreviousPath',
  CUSTOMER_CATEGORY_MSA: 'MSA',
  CUSTOMER_CATEGORY_NONMSA: 'NONMSA',
  NON_MSA_PAYMENT_OPTION_SALES_ORDER: 1,
  NON_MSA_PAYMENT_OPTION_REAPIR_ORDER: 2,
  NON_MSA_PAYMENT_OPTION_S_KEY: 3,
  NON_MSA_HW_OPTION_CUSTOMER_SO: 'Key with Service Order',
  NON_MSA_HW_OPTION_CUSTOMER_PO: 'Key with Customer PO',
  NON_MSA_HW_OPTION_CUSTOMER_KEY_ONLY: 'Key Only',
  NON_MSA_SERVICE_ORDER: 'REPAIR ORDER',
  NON_MSA_PURCHASE_ORDER: 'SALES ORDER',
  NON_MSA_KEY_ONLY_ORDER: 'Pay for Encryption Key Only',
  NON_MSA_SERVICE_ORDER_VALIDATE_STATUS: 'Invalid',
  ASPIRE_CATEGORY_NAME: 'aspire',
  APU_CATEGORY_NAME: '131-9hem',
  DLMUW_CATEGORY_NAME: 'dlmu-w',
  OTHERS_CATEGORY_NAME: 'others',
  ANALYZER_SERVICE_CENTER_PRODUCT_NAME: 'Analyzer Service',
  FALUT_ANALYZER_SERVICE_CENTER_PRODUCT_NAME: 'Fault Analyzer',
  REMOTE_TERMINAL_BASIC_SERVICE_PRODUCT_NAME: 'Remote Terminal',
  APP_DEBUG_ENABLE: 'APP_DEBUG_ENABLE',
  /** Constants for configure product tab pane */
  CONFIGURE_PRODUCT_CONFIG_TAB: 0,
  /** Constants for configure product tab pane - Bulk upload */
  CONFIGURE_PRODUCT_BULK_TAB: 1,
  EMPTY_CART_MESSAGE: 'No products in your cart!',
  ORDER_DETAILS: 'orderDetails',
  FAILURE_ORDER_RESPONSE: 'failureOrderResponse',
  APPTUS_SYS_NAME: 'Apttus',
  BMS_SYS_NAME: 'BMS',
  APU_ITEMS_DELETE_SUCCESS: '0',
  ASPIRE_PRODUCT_NAME: 'aspire 400',
  APU_PRODUCT_NAME: '131-9',
  DLMUW_PRODUCT_NAME: 'dlmu-w',
  ANALYZER_SERVICE_CENTERS_PRODUCT_NAME: 'analyzer service center',
  FAULT_ANALYZER_SERVICES_PRODUCT_NAME: 'fault analyzer services',
  REMOTE_TERMINAL_SERVICES_PRODUCT_NAME: 'remote terminal basic services',
  ASPIRE_PRODUCT_CLASSNAME: 'aspire-icon',
  APU_PRODUCT_CLASSNAME: 'apu-icon',
  DLMUW_PRODUCT_CLASSNAME: 'adg100-icon',
  FAULT_PRODUCT_CLASSNAME: 'fault-analyzer',
  ANALYZER_PRODUCT_CLASSNAME: 'analyzer-service',
  REMOTE_TERMINAL_PRODUCT_CLASSNAME: 'remote-terminal',
  FAULT_ANALYZER_SERVICES_PRODUCT_ID: '192',
  REMOTE_TERMINAL_SERVICES_PRODUCT_ID: '230',
  DLMUW_PRODUCT_ID: '190',
  /** Used for page distingusion in CartListComponent */
  MYCART_PAGE: 'myCart',
  /** Used for page distingusion in CartListComponent */
  REVIEW_ORDER_PAGE: 'reviewOrder',
  SELECT_ACCOUNT_SEARCH_BY_HONID: 'HON_ID',
  SELECT_ACCOUNT_SEARCH_BY_EMAILID: 'EMAIL_ID',
  SELECT_ACCOUNT_SEARCH_BY_SOLDTO: 'SOLD_TO',
  OPERATION_TYPE_SAVE: 'save',
  OPERATION_TYPE_EDIT: 'edit',
  OPERATION_TYPE_DELETE: 'delete',
  OPERATION_TYPE_DELETE_ALL: 'deleteAll',
  APU_AIRBUS_PRODUCT_NAME : '131-9a',
  APU_BOEING_PRODUCT_NAME : '131-9b'

};

export const CheckoutServiceURL = {
  ADS_DASHBOARD_URL: 'ADS_DASHBOARD_URL',
  MY_LICENSES_URL: 'MY_LICENSES_URL',
  NEED_HELP_URL: 'NEED_HELP_URL',
  USER_GUIDE_URL: 'USER_GUIDE_URL',
  ACCESS_TOKEN: 'BACKEND_ACCESS_TOKEN',
  ACCESS_TOKEN_URL: 'FORGE_ACCESS_TOKEN_URL',
  LOGOUT_URL: 'FORGE_LOGOUT_URL',
  MANAGE_ACCOUNT_INFO: 'MANAGE_ACCOUNT_INFO',
  USERINFO_URL: 'APP_USERINFO_URL',
  BACKEND_BASE_URL: 'BACKEND_BASE_URL',
  PAYMETRIC_UI_URL: '/api/v1/payments/paymetric-details',
  COUNTRY_URL: '/api/v1/countries',
  SAVE_USER_ACCOUNT: '/api/v1/users',
  SFDC_CASE_STATUS_URL: '/api/v1/users',
  ADMIN_ADDRESS_DETAILS_URL: '/api/v1/accounts',
  NEW_ADDRESS_REGISTRATION_URL: '/api/v1/users',
  PRODUCT_LISTING_URL: '/api/v1/products',
  CUSTOMER_CBD_STATUS_URL: '/api/v1/pay-terms',
  GET_PRODUCT_FEATURE_LIST_URL: '/api/v1/products/',
  GET_CART_DETAILS_URL: '/api/v1/users/',
  CART_URL: '/api/v1/users/',
  PLACE_ORDER_REQUEST_URL: '/api/v1/users/',
  GET_CC_PAYMETIRC_META_INFO: '/api/v1/payments',
  GET_CONTACT_DETAILS_URL: '/api/v1/contacts/',
  GET_AIRCRAFT_OEM: 'getAircraftOEM/',
  READ_EXCEL_CONFIGURATION: '/api/v1/products',
  CONTACT_US_URL: 'https://ads.honeywell.com/support/form',
  GET_CONTRACT_DETAILS: '/api/v1/contracts',
  GET_AIRCRAFT_TYPES_URL: '/api/v1/products/',
  CHECK_EXPIRED_CONTRACTS: '/api/v1/users',
  GET_TERMS_CONDITION_URL: '/api/v1/terms-and-conditions',
  FORGE_ADS_REDIRECT_URL: 'FORGE_ADS_REDIRECT_URL',
  APP_ADS_CLIENT_ID: 'APP_ADS_CLIENT_ID',
  JOULE_REDIRECT_URL: 'JOULE_REDIRECT_URL',
  FORGE_SSO_URL: 'FORGE_SSO_URL',
  AEROSPACE_LOGOUT_URL: 'AEROSPACE_LOGOUT_URL',
  HAS_ASDS_ACCESS: '/api/v1/checkout-access',
  ASDS_USER_ROLE_URL: '/api/v1/is-admin',
  ASDS_ROLE_ACCESS_URL: 'ASDS_ROLE_ACCESS_URL',
  PURCHASE_ORDER_URL: '/api/v1/purchase-orders?soldTo=',
  SERVICE_ORDER_URL: '/api/v1/validate-service-orders',
  ORDER_HISTORY_URL: '/api/v1/orders',
  INVOICE_URL: '/api/v1/invoices'
};

/**
* @author: H175591
* @description: This property deals with UI labels used in checkout module.
*/
export const CheckoutUILabels = {
SEARCH_TERM_INVALID_PATTER:
  'Please enter a valid search term. It allows characters & numbers A-Z, a-Z, 0-9 . , - _  / &.',
PRODUCTS_NOT_FOUND_FOR_SEARCH_TERM: 'No products found for your search.',
PRODUCTS_NOT_FOUND_FOR_SEARCH: 'Oops! There are no results for your search!',
PRODUCTS_LOADING_ERROR:
  'We are unable to process your request at this time. Please try again after sometime.',
DELETE_PROD_ERROR_MSG:
  'Please try deleting the product after sometime. If the issue persists,',
IF_ISSUE_PERSISTS: 'If the issue persists,',
PRODUCTS_NOT_FOUND: 'No Products Found.',
ALERT_PENDING_HEADER: 'Notice',
ALERT_PENDING_MSG: 'Request Pending.',
ALERT_PENDING_DESC: 'Your request is being processed.',
ALERT_PENDING_DESC1:
  '. Please contact dsa@honeywell.com for further assistance.',
NOT_ABLE_TO_ADD_APPTUS_PRODUCT_ALERT_MSG:
  'You will be able to purchase only Aspire 400 and 131-9 APU products together at one go. ' +
  'All other products cannot be clubbed with Aspire & APU products. Please complete this purchase and initiate a fresh purchase to checkout for any other products.',
NOT_ABLE_TO_ADD_BMS_PRODUCT_ALERT_MSG:
  'You will not be able to purchase DLMU-W along with Aspire 400 and 131-9 APU products. ' +
  'All other products in the catalog can be clubbed with DLMU-W products purchase. Please complete this purchase and initiate a fresh purchase to checkout for any other products.',
NO_PURCHASE_ORDER_ERROR: 'The selected SoldTo does not have purchase orders. Please change SoldTo in the Select Account Screen. If you need any help,',
  ADDED_TO_CART_MSG: 'Added to Cart',
CHECKOUT_BTN: 'Checkout',
VIEW_CART_BTN: 'View Cart',
TAXES_SHIPPING_APLICABLE: '+ Taxes as applicable.',
CART_SUB_TOTAL: 'Cart Subtotal',
APU_TERMS_ERROR_MSG:
  'Please select this checkbox to confirm.',
CONFIGURE_BTN: 'configure',
CONFIGURATION_ADD_TO_CART_BTN: 'Add to cart',
CONFIGURATION_SAVE_BTN: 'Save',
CONFIGURATION_NEXT: 'Next',
CONFIGURATION_CONFIRM: 'Confirm',
PRODUCT_PRICE_UPDATE: 'Your Cart has been Updated !',
PRODUCT_PRICE_UPDATE_MSG: 'Prices of some products have been automatically updated in your Cart.',
PO_PRODUCT_DELETE_MSG: 'Some NonMSA Purchase Order products have been automatically deleted in your cart as the quantity available for the selected purchase order numbers has changed in the system.',
ADDED_TO_CART_SUCCESS_MSG: ' is successfully added in your cart.',
ADD_TO_CART_SUCCESS_HEADING: 'Successfully added in your Cart!',
UPDATE_TO_CART_SUCCESS_MSG: ' is successfully updated in your cart.',
UPDATE_TO_CART_SUCCESS_HEADING: 'Successfully updated in your Cart!',
APU_PRODUCT_TERMS_LABEL:
  'I confirm that APU serial numbers which are being configured is part of the selected MSA contract.',
WIRELESS_PRODUCT_TERMS_LABEL:
  'I confirm the Aircrafts are registered at selected shipping address and that multiple shipping locations require separate orders.',
WIRELESS_PRODUCT_TERMS_ERROR_MSG:
  'Please select this checkbox to confirm.',
NOTIFICATION_SEVERITY_CRITICAL: 'critical',
NOTIFICATION_SEVERITY_IMPORTANT: 'important',
STANDARD_ERROR_MESSAGE: 'There is an error in processing your request.',
PLEASE_TRY_AGAIN: 'Please Try Again.',
// CREDIT_CART_PAYMENT_FAILIURE_MSG:
//  'The server encountered an error with your Credit Card.Either it has insufficient funds or has exceeded its limit.Honeywell Support will contact you within the next 24-48 hours regarding your order.',
CREDIT_CART_PAYMENT_FAILIURE_MSG:
 'There is an error in processing your request. Please try again.',
DELETE_ALL_MSA_PRODUCT:
'All product(s) configured and added to the cart are successfully deleted.'

};

export const ApuErrorLabels = {
NONMSA_OEM_ERROR: 'Please select APU Model.',
NONMSA_SERIAL_ERROR: 'Please enter valid 4 to 8 digit APU Serial Number.',
NONMSA_DUPLICATE_SERIAL_ERROR:
  'Serial Number is already added. Please enter a new Serial Number.',
NONMSA_PAYMENT_ERROR: 'Please select Obtaining Hardware Via',
NONMSA_PURCHASE_ORDER_ERROR: 'Please select Purchase Order.',
NONMSA_PURCHASE_ORDER_QUANTITY_ERROR_TITLE: 'Quantity exceeded for selected Purchase Order!',
NONMSA_PURCHASE_ORDER_QUANTITY_ERROR: 'Please delete the row or select another Purchase Order with remaining quantity.',
NONMSA_SALES_ORDER_ERROR: 'Please select Sales Order.',
NONMSA_REPAIR_ORDER_ERROR: 'Please select Repair Order.',
NONMSA_SERVICE_ORDER_EMPTY_ERROR: 'Please enter a value in the  Service Order No. field before proceeding further.',
NONMSA_SERVICE_ORDER_DUBLICATE_ERROR: 'Service Order is already added. Please enter a new Service Order .',
NONMSA_SERVICE_ORDER_DUBLICATE_CART_ERROR: 'This Service Order  is already configured and added in the cart. Please enter new Service Order  to move ahead (or) modify the existing product in your cart.',
NONMSA_SERVICE_ORDER_INVALID_TITLE: 'This Service Order mentioned above does not exist!',
NONMSA_SERVICE_ORDER_INVALID_ERROR : 'The Service Order you have entered does not match our records. Please enter the correct Service Order and try again.',
ALL_FIELDS_EMPTY_ERROR : 'All Fields are Mandatory!',
SERIAL_NUMBER_DUBLICATE : 'This APU Serial Number mentioned above already exist!',
SERIAL_NUMBER_INVALID: 'This APU Serial Number mentioned above is invalid!',
SERVICE_ORDER_DUBLICATE: 'This APU Service Order mentioned above already exist!',
SERVICE_ORDER_NO_INVALID: 'This APU Service Order mentioned above is invalid!',
NON_MSA_SERVICE_ORDER_ERROR_INVALID:
  'Please enter APU Service Order. It allows numbers (0-9) with 10 digits.',
MSA_SOLDTO_ERROR_EMPTY: 'Please enter or select Sold To Number.',
MSA_SOLDTO_ERROR_INVALID:
  'Please enter valid SoldTo number. It allows alphabets & numbers (a-z, A-Z, 0-9)',
MSA_CONTRACT_ERROR: 'Please select Contract Number.',
MSA_EXPIRYDT_ERROR: 'Please select Expiration date.',
MSA_OEM_ERROR: 'Please select APU Model.',
MSA_SERIAL_ERROR_EMPTY: 'Please enter APU Serial Number.',
MSA_SERIAL_ERROR_INVALID:
  'Please enter APU Serial number. It allows numbers (0-9) in between 4 to 8 digits, which are greater than 2000.',
MSA_DUPLICATE_SERIAL_ERROR:
  'Serial Number is already added. Please enter a new Serial Number.',
DUPLICATE_SERIAL_CART_ERROR:
  'This Serial Number is already configured and added in the cart. Please enter new Serial Number to move ahead (or) modify the existing product in your cart.',
EXPIRED_CONTRACTS_DELETED_MSG:
  'Expired APU MSA contracts which were added in your cart are deleted',
  NON_MSA_KEY_ONLY_PERIOD: 'Please select Subscription Period.',

};

export const AircraftErrorLabels = {
AIRCRAFT_TYPE_ERROR: 'Please select Aircraft Model.',
AIRCRAFT_SERIAL_ERROR_EMPTY: 'Please enter Aircraft Serial No.',
AIRCRAFT_SERIAL_ERROR_INVALID:
  'Aircraft Serial number can contain only (A-Z, a-z), digits (0-9), or the character -',
AIRCRAFT_DUPLICATE_SERIAL_ERROR:
  'Aircraft Serial Number is already added. Please enter a new Serial Number.',
DUPLICATE_SERIAL_CART_ERROR:
  'This Aircraft Serial Number is already configured and added in the cart. Please enter new Serial Number to move ahead (or) modify the existing product in your cart.',
AIRCRAFT_TAIL_ERROR_EMPTY: 'Please enter Aircraft Tail No.',
AIRCRAFT_TAIL_ERROR_INVALID:
  'Aircraft Tail number can contain only (A-Z, a-z), digits (0-9), or the character -',
AIRCRAFT_DUPLICATE_TAIL_ERROR:
  'Tail Number is already added. Please enter a new Tail Number.',
DUPLICATE_TAIL_CART_ERROR:
  'This Tail Number is already configured and added in the cart. Please enter new Tail Number to move ahead (or) modify the existing product in your cart.',
  ALL_FIELDS_EMPTY_ERROR : 'All Fields are Mandatory!',
AIRCRAFT_SERIAL_NUMBER_DUBLICATE : 'This Serial Number mentioned above already exist!',
AIRCRAFT_SERIAL_NUMBER_INVALID: 'This Serial Number mentioned above is invalid!',
AIRCRAFT_TAIL_NUMBER_DUBLICATE : 'This Tail Number mentioned above already exist!',
AIRCRAFT_TAIL_NUMBER_INVALID: 'This Tail Number mentioned above is invalid!',
};

export default CheckoutConstants;