import { AsdsReducerObject } from '../../../../../types';

/**
 * @author ASDS Team
 * @description Contains common logic accress all files that fall under reducer.
 */
class Reducer {

    static errorReducer(state: object): AsdsReducerObject {
        return {payload: {...state} , isSuccess : false , isFetching : false};
    }
}

export default Reducer;