import React from 'react';
import { Grid } from '@scuf/common';
import '@scuf/common/honeywell/theme.css';
import BannerComponent from '../BannerComponent/bannerComponent';
import UserInfoHeader from '../UserInfo/UserInfoHeader';

/**
 * @author ASDS Team
 * @description Layout for purchase software page.
 * @param props 
 */
function Layout(props) {
    return (
        <Grid className="fullwidthRow">
            <Grid.Row className="fullwidthRow">
                <Grid.Column width={12} className="fullwidthRow" >
                    <BannerComponent />
                </Grid.Column>
            </Grid.Row>

            {/*User Info Hader should be rendered across checkout screens*/} 
            <UserInfoHeader />
            {props.children}
        </Grid>
    );
}

export default Layout;