import * as React from "react";

//This function is used to display Shipping address component
import {IShippingAddressProps} from "../utils/checkout-interfaces";
import AddressDetails from './AddressDetails';

function ShippingAddressInfo(props: IShippingAddressProps) {
    return (
        <AddressDetails addressDetails = {props.addressDetails}>
            <p className="helpTextItalic">
                Shipping charges do not apply for electronic
                downloads.
            </p>
        </AddressDetails>
    );
}

export default ShippingAddressInfo;