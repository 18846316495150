
export const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);

export const validateAPUSerialNumber = (serialNumber) => {
    let hasErrors = false;
    if (!serialNumber || isNaN(serialNumber) || serialNumber < 2000 || serialNumber.length > 8){
        hasErrors = true;
    }
    return hasErrors;
};

export const validateAPUServiceOrderNumber = (serviceOrderNumber) => {
    let hasErrors = false;
    if (!serviceOrderNumber  ||  isNaN(serviceOrderNumber) || serviceOrderNumber.length !== 10 ) {
        hasErrors = true;
    }

    return hasErrors;
};


export const validateSoldTo = (soldTo) => {
    let hasErrors = false;
    if (!soldTo){
        hasErrors = true;
    }
    return hasErrors;
};

export const validateContractNum = (contractNum) => {
    let hasErrors = false;
    if (!contractNum){
        hasErrors = true;
    }
    return hasErrors;
};

export const validatePurchaseOrderNum = (purchaseOrderNum) => {
    let hasErrors = false;
    if (!purchaseOrderNum) {
        hasErrors = true;
    }
    return hasErrors;
};

export const validateAircraftOEM = (aircraftOEM) => {
    let hasErrors = false;
    if (!aircraftOEM){
        hasErrors = true;
    }
    return hasErrors;
};