import { combineReducers } from 'redux';
import * as userValidationData from './userValidationReducer';
import * as productListing from './product-listing-reducer';
import * as categoryListing from './category-listing-reducer';
import * as paymetricData from './paymetric-reducer';
import * as selectAccount from './select-account-reducer';
import * as configProductData from './configure-product-reducer';
import * as cartDetails from './cart-details-reducer';
import * as shippingBillingData from './shipping-billing-reducer';
import * as orderSummary from './order-summary-reducer';
import * as placeOrderRequest from './place-order-request-reducer';
import * as configAPUData from './configure-apu-reducer';
import * as errorReducer from './error-reducer';
import * as configWirelessProductData from './configure-wireless-reducer';
import * as productAlertData from './products-alerts-reducer';
import * as orderHistoryData from './order-history-reducer';

/**
 * @author ASDS Team
 * @description Combines all reducers based on imported files.
 * No need for adding each reducer, spread operator does that for you.
 */
export default combineReducers({
    ...userValidationData, ...productListing, ...categoryListing, ...paymetricData,
    ...configProductData, ...selectAccount, ...cartDetails, ...shippingBillingData, ...orderSummary,
    ...placeOrderRequest, ...configAPUData, ...errorReducer, ...configWirelessProductData, ...productAlertData,
    ...orderHistoryData
});