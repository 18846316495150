const routeConstants = {
    SELECT_ACCOUNT: '/selectaccount',
    PAYMENT_SCREEN: '/payment-screen',
    NEW_ADDRESS: '/newaddress',
    SUCCESS_MESSAGE: '/successmessage',
    PURCHASE_SOFTWARE: '/Purchase-Software',
    ADD_SCM_SERIALNUMBERS: '/addScmSerialNumbers',
    REQUEST_STATUS: '/request-status',
    PURCHASE_SOFTWARE_STATIC: '/purchasesoftwar',
    SHIPPING_BILLING: '/shipping-billing',
    ADS_DASHBOARD: '/Ads-Dashboard',
    MY_CART: '/myCart',
    REVIEW_ORDER: '/review-order',
    ORDER_SUCCESS: '/order-success',
    ORDER_FAILURE: '/order-failure',
    ORDER_HISTORY: '/order-history',
    USER_VERIFICATION_SELECT_ACCOUNT: '/select-account',
    LOGOUT: '/logout',
    ERROR_PAGE: '/error-page',
    USER_INFO: '/userInfo',
    DEFAULT_ROUTE: '/',
};

export default routeConstants;